/**
 * 路由配置
 */
import LayoutIndex from '@/layout/Index';
import AppMainLayout from '@/layout/components/AppMain';
const activityRouter = {
  path: '/act',
  component: LayoutIndex,
  redirect: '/act/activity/list',
  name: 'act',
  meta: { title: '酒卡活动', icon: 'page', roles: ['admin', 'system'] },
  children: [
    {
      path: 'activity',
      name: 'activity',
      component: AppMainLayout,
      redirect: '/Activity/activity_list',
      meta: { title: '活动管理', icon: 'pagelist', roles: ['admin', 'system'] },
      children: [
        {
          path: 'list',
          component: () => import('@/views/Activity/index'),
          name: 'activity_list',
          meta: { title: '活动列表', icon: 'pagedetails', roles: ['admin', 'system'] }
        }, {
          path: 'editinfo',
          hidden: true,
          component: () => import('@/views/Activity/editinfo'),
          name: 'activity_editinfo',
          meta: { title: '编辑基础信息', icon: 'pagedetails', roles: ['admin', 'system'] }
        }, {
          path: 'editbanner',
          hidden: true,
          component: () => import('@/views/Activity/editbanner'),
          name: 'activity_editbanner',
          meta: { title: '编辑广告图', icon: 'pagedetails', roles: ['admin', 'system'] }
        }, {
          path: 'editproduct',
          hidden: true,
          component: () => import('@/views/Activity/editproduct'),
          name: 'activity_editproduct',
          meta: { title: '编辑产品信息', icon: 'pagedetails', roles: ['admin', 'system'] }
        }, {
          path: 'editsetting',
          hidden: true,
          component: () => import('@/views/Activity/editsetting'),
          name: 'activity_editsetting',
          meta: { title: '编辑配置信息', icon: 'pagedetails', roles: ['admin', 'system'] }
        }, {
          path: 'editcoupon',
          hidden: true,
          component: () => import('@/views/Activity/editcoupon'),
          name: 'activity_editcoupon',
          meta: { title: '编辑优惠券', icon: 'pagedetails', roles: ['admin', 'system'] }
        }, {
          path: 'scenelist',
          hidden: true,
          component: () => import('@/views/Activity/scenelist'),
          name: 'activity_scenelist',
          meta: { title: '渠道列表', icon: 'pagedetails', roles: ['admin', 'system'] }
        }
      ]
    },
    {
      path: 'shop',
      component: AppMainLayout,
      redirect: '/views/Shop/index',
      meta: { title: '门店管理', icon: 'control', roles: ['admin', 'system'] },
      children: [
        {
          path: 'shoplist',
          component: () => import('@/views/Shop/index'),
          name: 'shoplist',
          meta: { title: '门店列表', icon: 'pagedetails', roles: ['admin', 'system'] }
        },
        {
          path: 'waiterlist',
          component: () => import('@/views/Shop/waiterlist'),
          name: 'waiterlist',
          meta: { title: '店员列表', icon: 'pagedetails', roles: ['admin', 'system'] }
        }
      ]
    }
  ]
};

export default activityRouter;
