import LayoutIndex from '@/layout/Index';
import AppMainLayout from '@/layout/components/AppMain';


const appuserRouter = {
  path: '/user',
  component: LayoutIndex,
  redirect: '/user/appuser/userlist',
  name: 'user',
  meta: { title: '用户模块', icon: 'page', roles: ['admin', 'system'] },
  children: [
    {
      path: 'appuser',
      name: 'appuser',
      component: AppMainLayout,
      redirect: '/appuser/userlist',
      meta: { title: '用户管理', icon: 'pagelist', roles: ['admin', 'system'] },
      children: [
        {
          path: 'userlist',
          component: () => import('@/views/AppUser/userlist'),
          name: 'userlist',
          meta: { title: '用户列表', icon: 'pagedetails', roles: ['admin', 'system'] }
        }
      ]
    }
  ]
}

export default appuserRouter;
