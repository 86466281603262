<template>
  <div class="indexlayout-right-footer">
    <div class="footer-links">
      <a href="http://naitcor.com" target="_blank">苏州耐特科</a>
      <a href="mailto:2296316065@qq.com" target="_blank">邮件联络</a>
      <a type="text" plain="true" @click="open">电话联络</a>
    </div>
    <div class="footer-copyright">
      Copyright © 2020 naitcor.com, All Rights Reserved
    </div>
  </div>
</template>
<script>
export default {
  name: 'LayoutIndexRightFooter',
  methods: {
    open() {
      this.$message.success('TEL：13771874652');
    },
  }
};
</script>
<style lang="scss" scoped>
.indexlayout-right-footer {
  padding: 48px 0 24px;
  height: 50px;
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  color: #808695;
  .footer-links {
    margin-bottom: 10px;
    a {
      margin-right: 30px;
      color: #808695;
      text-decoration: none;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: #515a6e;
      }
    }
  }
}
</style>